.parallax-image {
    background: fixed no-repeat top;
    background-size: auto 100vh;
    margin-top: -100vh;
    height: 100vh;
    position: sticky;
    top: 0;
    z-index: -1;

    @media (min-width: 768px) {
        background-size: cover;
        background-position: center;
    }
}