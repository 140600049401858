.hero-container {
    height: 90vh;
    background: transparent;
}

.hero-img {
    width: 100%;
    height: auto;
}

.hero-text {
    background-color: rgba(0,0,0,0.3);
    color: white;
}

