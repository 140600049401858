.headshot {
  height: auto;
  width: 200px;
  border-radius: 50%;
}

.bg-red {
  background-color: rgb(203, 55, 51);
}

.text-red {
  color: rgb(203, 55, 51);
}
